<template>
  <div class="w-full h-full pt-5 px-3">
    <template>
      <div class="flex flex-row justify-start gap-4">
        <back-button
          label="Back"
          @onClick="$router.back()"
          variant="secondary"
          class="mr-4"
        />
        <div class="flex gap-3 my-2">
          <h1 class="text-xl text-left font-extrabold mt-1">Employees</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else class="px-3 my-2 flex w-full flex-col">
        <div class="flex flex-col w-full h-full">
          <div class="w-full flex py-2 gap-1 border-b-2">
            <p class="text-lg font-bold">Confirmation Checklist</p>
          </div>
          <employment-details :employment-details="employeeDetails" />
        </div>

        <div class="w-full flex my-2 justify-center">
          <div class="my-3 w-4/5 border-2" />
        </div>

        <div class="w-full my-8 flex gap-3 px-3">
          <div class="w-1/4 flex flex-col p-3">
            <side-bar
              :tabs="['Intro', 'Confirmation Assessment']"
              :current-tab="currentTab"
              @currentTab="currentTab = $event"
            />
          </div>
          <div class="flex flex-col border" style="width: 1px" />

          <div class="w-3/4 flex flex-col">
            <intro
              :employee-details="employeeDetails"
              v-if="currentTab === 'Intro'"
            />

            <confirmation-form
              :employment-details="employeeDetails"
              :settings="settingsData"
              v-if="currentTab === 'Confirmation Assessment'"
            />
          </div>
        </div>

        <div class="flex justify-end">
          <div
            class="flex"
            :class="currentTab === 'Intro' ? 'opacity-100' : 'opacity-50'"
          >
            <card class="w-10 text-xl mr-5 font-extrabold h-10">
              <p class="text-center my-2">1</p>
            </card>
            <div
              class="flex mt-2 cursor-pointer"
              @click="currentTab = 'Confirmation Assessment'"
            >
              <div class="font-10 mr-4">
                <p class="text-flame">Next</p>
                <p class="uppercase font-semibold">Basic Info</p>
              </div>
              <Icon size="xs" icon-name="icon-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import employmentDetails from "@/components/confirmation/employmentDetails";
import Card from "@/components/Card";
import SideBar from "./sideBar";
import Intro from "./layout/intro";
import ConfirmationForm from "./layout/confirmationForm";

export default {
  name: "ViewAssesment",
  components: {
    BackButton,
    Breadcrumb,
    employmentDetails,
    Card,
    SideBar,
    Intro,
    ConfirmationForm,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Employees",
          href: "Employees",
          id: "Employees",
        },
        {
          disabled: false,
          text: "Employee Directory",
          href: "EmployeeDirectory",
          id: "Employee Directory",
        },
        {
          disabled: false,
          text: "Confirmation Checklist",
          id: "Confirmation Checklist",
        },
      ],
      employeeDetails: {},
      settingsData: {},
      loading: true,
      currentTab: "Intro",
    };
  },

  mounted() {
    if (this.$route.query.slug === 'admin') {
      this.$_employeeOnProbation("").then((response) => {
        const filteredEmployee = response.data.data.employees.filter(
          (x) => x.id === this.$route.params.id
        );
        this.employeeDetails = Object.assign({}, ...filteredEmployee);
        this.loading = false;
      });
    }
    else {
      this.$_employeesConfirmation("").then((response) => {
        const filteredEmployee = response.data.data.employees.filter(
          (x) => x.id === this.$route.params.id
        );
        this.employeeDetails = Object.assign({}, ...filteredEmployee);
        this.loading = false;
      });
    }

    this.$_getConfirmationSettings()
      .then((response) => {
        const {
          confirmationMandatory,
          criteria,
          defaultExtendPeriod,
          probationPeriod,
          ratings,
          exception,
        } = response.data.confirmationProbationRating;

        let criterias = [];
        if (criteria.length > 0) {
          const selected = criteria.filter(item => item.isSelectable);
          criterias = selected.length > 0 ? selected : criteria
        }

        this.settingsData = {
          confirmationMandatory,
          criteria: criterias,
          defaultExtendPeriod,
          probationPeriod,
          ratings,
          exception,
        };
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};
</script>