<template>
  <div class="w-full flex flex-col flex-grow p-4 gap-4 border ml-3">
    <p class="text-romanSilver text-sm">Comment</p>
    <p class="text-sm">
      You have initiated the process of confirming
      {{ employeeDetails.fname }} {{ employeeDetails.lname }}. Before you
      proceed, please review the confirmation checklist.
    </p>
  </div>
</template>

<script>
export default {
  name: "Intro",
  props: {
    employeeDetails: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
